import axios from 'axios';
import store from '@/store/store';

const api = axios.create({
  baseURL: process.env.VUE_APP_AUTH_ENDPOINT,
  timeout: 2500, // 2.5 seconds
});

// console.log('This did something'); // eslint-disable-line

const getUserProfile = (jwt) => api.post(
  'auth/authcCognitoIDToken',
  jwt,
  // For some reason CORS no longer likes this???
  // {
  //   headers: {
  //     // 'Content-Type': 'application/text',
  //   },
  // },
)
  .then((response) => {
    if (!response.data || response.data.status === false) {
      return response.data.message;
    }

    // console.log(response.data); // eslint-disable-line

    const userDetails = Object.keys(response.data.userDetails)
      .filter((key) => response.data.userDetails[key] !== null)
      .reduce((obj, item) => ({ ...obj, [item]: response.data.userDetails[item] }), {});

    // Profile
    const profile = { ...response.data, userDetails };

    // TODO: Make all things below a bit more elegant
    // Admin
    const admin = userDetails.permissions.includes('apphub|admin');

    // App Access
    const portalAccess = userDetails.permissions.includes('portal'); // portal|viewer
    const reportAccess = userDetails.permissions.includes('reports|viewer');
    const apiAccess = userDetails.permissions.includes('api|viewer');
    const activityAccess = userDetails.permissions.includes('activity|viewer');
    const bookSnapshotAccess = userDetails.permissions.includes('booksnapshot|viewer');

    // Client
    // const client = userDetails.permissions
    //   .filter((v) => `${v}`.startsWith('clientPortal|'))
    //   .map((v) => v.split('|')[1]);
    const client = userDetails.configuration.client; // eslint-disable-line

    // Using Vuex instead of localStorage below
    // User Profile
    store.commit('portalUser', profile);

    // User Client
    // If no client set in state, set it.
    if (store.state.client === '') {
      store.commit('client', client);
      console.log('Set Client: ' + client); // eslint-disable-line
    }

    // const client = userDetails.configuration.accountId;
    // const accountId = userDetails.configuration.accountId; // eslint-disable-line

    // Account ID
    // If no account id set in state, set it.
    // if (store.state.accountId === '') {
    //   store.commit('accountId', accountId);
    // }

    // App Access
    store.commit('isAdmin', admin);
    // console.log('Set Admin: ' + admin); // eslint-disable-line

    store.commit('portalAccess', portalAccess);
    // console.log('Set Portal Access: ' + portalAccess); // eslint-disable-line

    store.commit('reportAccess', reportAccess);
    // console.log('Set Report Access: ' + reportAccess); // eslint-disable-line

    store.commit('apiAccess', apiAccess);
    // console.log('Set API Access: ' + apiAccess); // eslint-disable-line

    store.commit('activityAccess', activityAccess);
    // console.log('Set Activity Access: ' + activityAccess); // eslint-disable-line

    store.commit('bookSnapshotAccess', bookSnapshotAccess);
    // console.log('Set Book Snapshot Access: ' + bookSnapshotAccess); // eslint-disable-line

    // localStorage.setItem('portalUser', JSON.stringify(profile));

    return response.data;
  });

export default getUserProfile;
