import { API } from 'aws-amplify';
import store from '@/store/store';

const getRefreshToken = (jwt) => {
  const refreshtokenData = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };

  return API.get('AppHubAPI', 'refreshtoken', refreshtokenData)
    .then(async (response) => {
      // if (!response.data || response.data.status === false) {
      //   return response.data.message;
      // }
      const user = Object.keys(response.user)
        .filter((key) => response.user[key] !== null)
        .reduce((obj, item) => ({ ...obj, [item]: response.user[item] }), {});

      // User Profile
      const profile = { ...response, user };

      // console.log(response.user); // eslint-disable-line

      // TODO: Make all things below a bit more elegant
      // Admin
      const admin = response.user.permissions.includes('apphub|admin');

      // App Access
      const portalAccess = response.user.permissions.includes('portal'); // portal|viewer
      const reportAccess = response.user.permissions.includes('reports|viewer');
      const apiAccess = response.user.permissions.includes('api|viewer');
      const activityAccess = response.user.permissions.includes('activity|viewer');
      const bookSnapshotAccess = response.user.permissions.includes('booksnapshot|viewer');

      // App Access
      store.commit('isAuth', true);
      store.commit('firstName', response.user.firstName);
      store.commit('lastName', response.user.lastName);
      store.commit('userEmail', response.user.email);

      store.commit('isAdmin', admin);
      store.commit('isAdmin', admin);
      store.commit('portalAccess', portalAccess);
      store.commit('reportAccess', reportAccess);
      store.commit('apiAccess', apiAccess);
      store.commit('activityAccess', activityAccess);
      store.commit('bookSnapshotAccess', bookSnapshotAccess);

      // User Profile
      // store.commit('newKratosUser', profile);
      store.commit('portalUser', profile);

      // const accountId = response.data.user.accountId;
      store.commit('accountId', response.user.accountId);
    });
};

export default getRefreshToken;
