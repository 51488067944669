<template>
  <div class="login">
    <!-- <amplify-authenticator username-alias="email">
      <amplify-sign-in slot="sign-in" :hide-sign-up="true"
        username-alias="email">
      </amplify-sign-in>
    </amplify-authenticator> -->
    <v-form>
      <v-card width="500">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="orange darken-2"
        ></v-progress-linear>
        <v-card-title>Log in to your account</v-card-title>
        <v-card-text>
            <v-text-field
              type="email"
              color="orange"
              v-model="email"
              prepend-icon="mdi-account"
              placeholder="Email Address"
              autocomplete="on"
            ></v-text-field>

            <v-text-field
              v-model="password"
              color="orange"
              prepend-icon="mdi-lock"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-password"
              autocomplete="on"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>

            <small>Forgot your password?
              <v-btn
                small
                text
                color="orange"
                to="/forgot"
              >Reset Password</v-btn>
            </small>

            <v-btn
              small
              text
              color="orange"
              to="/sso"
            >Log in via SSO</v-btn>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block large dark
                color="orange"
                :disabled="loading"
                :loading="loading"
                @click="login"
              >Log In</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar v-model="error">
      <v-icon>mdi-alert-circle-outline</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
// import store from '@/store/store';
import getUserProfile from '@/services/getUserProfile';
import getRefreshToken from '@/services/getRefreshToken';
// import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: 'Login',
  data() {
    return {
      error: false,
      message: '',
      email: '',
      loading: false,
      password: '',
      show1: false,
      user: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`), // eslint-disable-line
      },
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await Auth.signIn(this.email, this.password); // SignIn

        this.loading = false; // Loading complete

        // Get User Profile from Internal Service
        const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

        // Set access token cookie
        // this.$cookies.set('access_token', jwt);

        getRefreshToken(jwt)
          .then(() => {
            // this.loading = false;
          })
          .catch((error) => {
            console.log(error); // eslint-disable-line
            this.error = true;
            this.message = error.message;
          });

        // Continue route or send to home
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect || '/');
        }

        // Or redirect for SSO login
        if (this.$route.query.redirect_uri) {
          window.location.href = `${this.$route.query.redirect_uri}?state=${this.$route.query.state}&code=ABC12345-AAA-BBB-AAA-12345678901`;
        }

        // console.log(user); // eslint-disable-line
      } catch (error) {
        console.log('error signing in', error); // eslint-disable-line
        this.loading = false;
        this.error = true;
        this.message = error.message;
      }
    },
    clearSessionKeys() {
      if (window && 'localStorage' in window) {
        Object
          .keys(localStorage).filter((key) => key.search(/CognitoIdentityServiceProvider/) >= 0)
          .forEach((key) => {
            localStorage.removeItem(key);
          });
      }
    },
    async getRefreshToken() {
      // const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
      // this.loading = true;
      // getRefreshToken(jwt)
      //   .then(() => {
      //     this.loading = false;
      //   })
      //   .catch((error) => {
      //     console.log(error); // eslint-disable-line
      //     this.error = true;
      //     this.message = error.message;
      //   });
    },
    tradeToken(jwt) {
      getUserProfile(jwt)
        .then(() => {
        // this.clearSessionKeys();
        // this.loading = false;
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          this.error = true;
          this.message = error.message;
        });
      // this.props.fetchProfile(jwt)
      // .then((things) => {
      //     this.clearSessionKeys();
      // })
      // .catch((ex) => {
      //     console.log(ex);
      // });
    },
    getSession() {
      // console.log('Getting session....'); // eslint-disable-line

      // Get by session
      const session = sessionStorage.getItem('idToken');
      // const session = this.state.auth.getCachedSession();

      // console.log('Session:'); // eslint-disable-line
      // console.log(session); // eslint-disable-line

      // Get by cookie
      const cookie = this.$cookie.get('access_token'); // document.cookie;

      // console.log('Cookie:'); // eslint-disable-line
      // console.log(cookie); // eslint-disable-line

      // If I have an access token, trade it in
      if (cookie) {
        this.tradeToken(cookie);
      }

      if ('idToken' in session) {
        console.log('Found idToken!'); // eslint-disable-line
        this.tradeToken(session.idToken.jwtToken);
        console.log('Traded token'); // eslint-disable-line
      } else {
        console.log('No idToken found.'); // eslint-disable-line
      }
      return false;

      // Auth.currentSession()
      // .then((response) => {
      //   console.log('response=', response);
      // })
      // .catch((error) => {
      //   // error below is "No current user"
      //   console.error('error=', error);
      // });
    },
  },
  mounted() {
    // Get current session if available
    this.getSession();
  },
};
</script>

<style scoped>
.login-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent
  linear-gradient(274deg,
  #F69B1E 0%,
  #F36D2E 46%,
  #F26630 100%) 0% 0% no-repeat padding-box;
}

@media(min-width:48rem) {
  .login-background {
      height: 100%;
      display: block;
      /* border-radius: 0 2rem 2rem 0; */
  }
}
</style>
