<template>
  <div class="sso">
    <v-container>
      <v-row align="center" justify="center">
        <v-col>
          <SSOComponent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import SSOComponent from '@/components/auth/SSOComponent.vue';

export default {
  name: 'SSOPassword',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'SSO',
  },
  components: {
    SSOComponent,
  },
};
</script>
