<template>
  <div class="login">
    <v-container fill-height fluid class="pa-0">
      <v-row align="center" class="fill-height">
        <v-col sm="12" md="6" class="login-background" align="center">
          <LoginComponent />
        </v-col>
        <v-col sm="12" md="6" align="center" class="py-6 px-12">
          <v-img
            alt="Login"
            contain
            src="@/assets/login_graphic.svg"
            transition="scale-transition"
            width="400"
          />
          <h2>One Solution for All Your Underwriting Needs</h2>
          <p>FRISS Underwriting Insights enables next-generation customer-centric
            digital underwriting models. Incorporating various streams
            of input, it provides a comprehensive risk profile
            that paints an accurate and complete picture of every risk.
            Leading P&C carriers use FRISS Underwriting Insights to provide a true
            digital experience, gain superior underwriting insight,
            and reduce their loss ratios.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginComponent from '@/components/auth/LoginComponent.vue';

export default {
  name: 'Login',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'Login',
  },
  components: {
    LoginComponent,
  },
};
</script>
