import axios from 'axios';
// import store from '@/store/store';

const api = axios.create({
  baseURL: 'https://apphub-api.underwriting.ai/', // process.env.VUE_APP_AUTH_ENDPOINT,
  // https://apphub-api-test.underwriting.ai/
  timeout: 2500, // 2.5 seconds
});

// const { host } = window.location;
const getDomainConfig = (domain) => api.get(
  `/getDomainConfig?domain=${domain}`, // ?domain=${host.split(':')[0]}` // westbend-test.terrenelabs.com
)
  .then((response) => response.data)
  .catch((error) => {
    console.log(error); // eslint-disable-line
  });

export default getDomainConfig;
